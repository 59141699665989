<template>
	<v-sheet color="transparent" class="pa-4">
		<v-row>
			<v-col>
				<v-text-field outlined v-model="search" :label="mwtr.SearchOrAdd" append-icon="mdi-tag-plus" @click:append="addNew"></v-text-field>
			</v-col>
		</v-row>
		<v-card flat color="secondary">
			<v-data-table :headers="headers" :items="items" :search="search">
				<template v-slot:item.button="{ item }">
					<ListButtons :id="item.id" collection="tags"></ListButtons>
				</template>
			</v-data-table>			
		</v-card>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import AddATag from "@/components/admin/panels/AddATag";
	import ListButtons from "@/components/admin/lists/ListButtons";

	export default {
		name: "Tags", 
		props: {
			
		},
		data: () => {
			return {
				search: "",
			}
		},
		components: {
			ListButtons,
			// AddATag
		},
		computed: {
			...Vuex.mapState({
				tags: state => state.tags
			}), 
			headers(){
				return [
					{value: "name", text: "Name"},
					{value: "button", text: " "},
				]
			}, 
			items(){
				return Object.values(this.tags)
			}
		},
		methods: {
			addNew(){
				const self = this;
				if( !self.search ){
					return;
				}
				self.$store.dispatch("tags/new", { name: self.search }).then(() => {
					self.search = "";
				})
			}
		},
		created(){
			this.$store.dispatch("tags/fetchAll")
      }

	}
// </script>"